var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Ujian")])]), _c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-lg",
      modifiers: {
        "modal-lg": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary",
      "to": {
        path: 'ujian-admin/a'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah")], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah-sub",
      "centered": "",
      "size": "xl",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalEdit,
      callback: function callback($$v) {
        _vm.ModalEdit = $$v;
      },
      expression: "ModalEdit"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian",
      "label-for": "nama-paket"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Ujian",
      "name": "nama-paket"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori Paket",
      "label-for": "kategori"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optKatPaket
    },
    model: {
      value: _vm.form.category_paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category_paket_id", $$v);
      },
      expression: "form.category_paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mode Paket",
      "label-for": "mode"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'default',
        text: 'Default'
      }, {
        value: 'pauli',
        text: 'Pauli'
      }]
    },
    model: {
      value: _vm.form.mode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mode", $$v);
      },
      expression: "form.mode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian (singkat)",
      "label-for": "harga"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "shortname",
      "placeholder": "shortname"
    },
    model: {
      value: _vm.form.shortname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shortname", $$v);
      },
      expression: "form.shortname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Acak Soal",
      "label-for": "acak"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optquestion
    },
    model: {
      value: _vm.form.shuffle_question,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shuffle_question", $$v);
      },
      expression: "form.shuffle_question"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tampilkan Hasil",
      "label-for": "show_result"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optresult
    },
    model: {
      value: _vm.form.show_result,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "show_result", $$v);
      },
      expression: "form.show_result"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instruksi",
      "label-for": "instruksi"
    }
  }, [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.form.instruction,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "instruction", $$v);
      },
      expression: "form.instruction"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kalkulasi Skor Ujian",
      "label-for": "type_score_calculation"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.typeScoreOptions
    },
    model: {
      value: _vm.form.type_score_calculation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type_score_calculation", $$v);
      },
      expression: "form.type_score_calculation"
    }
  }), _c('small', {
    staticClass: "my-1"
  }, [_c('strong', [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v("Jika ingin membuat ujian Tes Kepribadian, pilih opsi "), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("\"Berdasarkan Poin Opsi Soal\"")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Kategori Paket")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "placeholder": "Pilih",
      "clearable": true,
      "options": _vm.optKatPaket
    },
    on: {
      "input": function input($event) {
        _vm.getData();
        _vm.getDataPaket();
      }
    },
    model: {
      value: _vm.filterKatPaket,
      callback: function callback($$v) {
        _vm.filterKatPaket = $$v;
      },
      expression: "filterKatPaket"
    }
  })], 1), _vm.filterKatPaket ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Paket")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optPaket
    },
    on: {
      "input": function input($event) {
        _vm.getData();
        _vm.getDataJadwal();
      }
    },
    model: {
      value: _vm.filterPaket,
      callback: function callback($$v) {
        _vm.filterPaket = $$v;
      },
      expression: "filterPaket"
    }
  })], 1) : _vm._e(), _vm.filterPaket && _vm.filterKatPaket ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Jadwal")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optJadwal
    },
    on: {
      "input": function input($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filterJadwal,
      callback: function callback($$v) {
        _vm.filterJadwal = $$v;
      },
      expression: "filterJadwal"
    }
  })], 1) : _vm._e()], 1), _c('b-row', [_vm.ujiandata.length >= 1 ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1) : _vm._e(), _vm.ujiandata.length >= 1 ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }], null, false, 478637150),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.filterKatPaket != null && _vm.filterPaket == null && _vm.filterJadwal == null ? _vm.fieldsKat : _vm.filterKatPaket != null && _vm.filterPaket != null && _vm.filterJadwal == null ? _vm.fieldsPaket : _vm.filterKatPaket != null && _vm.filterPaket != null && _vm.filterJadwal != null ? _vm.fieldsJadwal : '',
      "items": _vm.ujiandata
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_vm.ujiandata.length == 1 ? _c('div', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                staticStyle: {
                  "height": "100px"
                },
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              path: "ujian-admin/detail/".concat(row.item.id),
              query: {
                category_paket_id: row.item.category_paket.id,
                paket: row.item.paket ? row.item.paket.id : 0,
                jadwal_id: row.item.jadwal ? row.item.jadwal.id : 0,
                ujian_id: row.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "SettingsIcon"
          }
        }), _c('span', [_vm._v("Detail dan Pilih Soal")])], 1), _vm.allowUpdate() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.allowDelete() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.ujiandata.length != 1 ? _c('div', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              path: "ujian-admin/detail/".concat(row.item.id),
              query: {
                category_paket_id: row.item.category_paket.id,
                paket: row.item.paket ? row.item.paket.id : 0,
                jadwal_id: row.item.jadwal ? row.item.jadwal.id : 0,
                ujian_id: row.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "SettingsIcon"
          }
        }), _c('span', [_vm._v("Detail dan Pilih Soal")])], 1), _vm.allowUpdate() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.allowDelete() ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " "), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.shortname) + " ")])];
      }
    }, {
      key: "cell(start_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.start_time)) + " ")];
      }
    }, {
      key: "cell(end_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.end_time)) + " ")];
      }
    }, {
      key: "cell(timer)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.timer + " menit") + " ")];
      }
    }, {
      key: "cell(sum_soal)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.sum_soal >= 1 ? data.item.sum_soal : 0) + " ")];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }])
  }), _vm.ujiandata.length == 0 && _vm.filterKatPaket == null ? _c('b-card', {
    staticClass: "mt-1",
    attrs: {
      "body-class": "text-center",
      "text-variant": "danger",
      "border-variant": "danger"
    }
  }, [_vm._v(" Silakan pilih kategori paket terlebih dahulu ")]) : _vm._e(), _vm.filterJadwal == null && _vm.filterKatPaket != null && _vm.ujiandata.length == 0 ? _c('b-card', {
    staticClass: "mt-1",
    attrs: {
      "body-class": "text-center",
      "text-variant": "danger",
      "border-variant": "danger"
    }
  }, [_vm._v(" Ujian tidak ada, silakan pilih paket yang lainnya ")]) : _vm._e(), _vm.filterJadwal != null && _vm.filterPaket != null && _vm.ujiandata.length == 0 ? _c('b-card', {
    staticClass: "mt-1",
    attrs: {
      "body-class": "text-center",
      "text-variant": "danger",
      "border-variant": "danger"
    }
  }, [_vm._v(" Ujian tidak ada, silakan pilih jadwal yang lainnya ")]) : _vm._e(), _c('br'), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }